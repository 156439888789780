import React from "react"
import { Link, graphql } from "gatsby"
import "./index.scss"

export const data = graphql`
  query index {
    contentfulDetails {
      date {
        file {
          url
        }
      }
      title
    }
    contentfulHome {
      mainImage {
        file {
          url
        }
        title
      }
    }
  }
`
const IndexPage = ({ data }) => {
  return (
    <div className="landing-page">
      <h1 className="page-title">Wednesday 5th January 2022</h1>
      <Link to="/details" className="landing-page_hero">
        <img
          className="landing-page-hero-img"
          src={data.contentfulHome.mainImage.file.url}
          alt={data.contentfulHome.mainImage.title}
        />
      </Link>
    </div>
  )
}

export default IndexPage
